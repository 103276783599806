import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './pages/auth/auth.component';
import { AuthGuard } from './shared/Auth.guard';
import { HelperService } from './shared/services/Helper.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: AuthComponent,
  },
  {
    path: 'event',
    canActivate: [AuthGuard],
  },
  {
    path: 'simulators',
    loadChildren: () =>
      import('./pages/content/simulators/simulators.module').then(
        (m) => m.SimulatorsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'staff',
    loadChildren: () =>
      import('./pages/content/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./pages/content/customers/customers.module').then(
        (m) => m.CustomersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'archive',
    loadChildren: () =>
      import('./pages/content/archive/archive.module').then(
        (m) => m.ArchiveModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'integration',
    loadChildren: () =>
      import('./pages/content/integration/integration.module').then(
        (m) => m.IntegrationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/content/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private helper: HelperService) {
    if (!this.helper.isMobileScreen()) {
      routes.find((f) => f.path === 'event').loadChildren = () =>
        import('./pages/content/event/event.module').then((m) => m.EventModule);
    } else {
      routes.find((f) => f.path === 'event').loadChildren = () =>
        import('./pages/content/event/mobile/event.mob.module').then(
          (m) => m.EventMobModule
        );
    }
  }
}
