import { environment } from "src/environments/environment";

export class Transaction {
  transactionType: TransactionType;
  eventId: number;
  tabId?: string;
  simulatorIdentifier?: string;
  simulatorName?: string;
  simulatorId?: number;
  locationId?: number;
  extendedMinutes?: number;
  hostName? : string;
  constructor(model: Transaction) {
    this.transactionType = model.transactionType || 0;
    this.eventId = model.eventId || 0;
    this.tabId = sessionStorage.getItem('BayManager-TabId') || '';
    this.simulatorIdentifier = model.simulatorIdentifier || '';
    this.simulatorName = model.simulatorName || '';
    this.simulatorId = model.simulatorId || 0;
    this.locationId = model.locationId || 0;
    this.extendedMinutes = model.extendedMinutes || null;
    this.hostName = environment.hostName.split('.').join("");
  }
}

export enum TransactionType {
  other,
  EventCreate,
  EventUpdate,
  EventDelete,
  EventCancel,
  EventExtend,
}
