import { Assistance } from './assistance';
import { ExtendSession } from './extend-session';
import { Signal } from './Signal';

export class WebSocketSignalModel {
  action?: string;
  payload?: Signal;
  extendSession?: any
  assistance?: Assistance
  constructor(model: WebSocketSignalModel) {
    this.action = model.action || '';
    this.payload = model.payload || (null as Signal);
    this.extendSession = model.extendSession || (null as any);
    this.assistance = model.assistance || (null as any);
  }
}
