<ng-container *ngIf="!isMobileScreen; else otherScreen">
    <nav class="side-nav">
        <ng-container *ngIf="!navLoader">
            <div class="full-width">
                <ul>
                    <li class="flex-center toggle-menu">
                        <button class="baseline" type="button" mat-icon-button (click)="toggleNavRoute()"></button>
                        <img src="/assets/icons/sidenav-fslogo.svg" alt="">
                    </li>
                    <hr style="width: 70%;">
                    <li class="flex-center" *ngFor="let item of navItems" [id]="'sn-' + item.name"
                        [class]="item.activate ? 'side-nav-active' : 'side-nav-dis'" [attr.data-nav-route]="item.route">
                        <button mat-icon-button type="button"
                            (click)="changeRoute(item.route, item.urlParam, item.name)"></button>
                        <img [src]="item.icon" [alt]="item.name">
                    </li>
                </ul>
            </div>
            <div class="full-width">
                <ul>
                    <li class="inner-logo">
                        <button mat-icon-button type="button" (click)="toggleNavRoute()"></button>
                        <div>
                            <img src="/assets/icons/sidenav-global.svg" alt="global">
                            <img [src]="'/assets/icons/' + locale.currentFlag.flagLogo" [alt]="locale.currentFlag.name">
                        </div>
                    </li>
                    <li style="position: relative;" id="sn-notifs">
                        <span *ngIf="notificationService.notificationCounts > 0" class="notification-badge"
                            [matBadge]="notificationService.notificationCounts" matBadgeSize="small"
                            matBadgeOverlap="false" matBadgeColor="warn"></span>
                        <button mat-icon-button type="button" (click)="showNotifs()"></button>
                        <img src="/assets/icons/sidenav-alert.svg" alt="">
                    </li>
                    <li>
                        <button mat-icon-button type="button" (click)="onLogoutClick()"></button>
                        <img src="/assets/icons/sidenav-logout.svg" alt="">
                    </li>
                    <li style="height: fit-content;">
                        <hr style="width: 86%;">
                    </li>
                    <li>
                        <button mat-icon-button type="button" (click)="onOpenVerModal()"></button>
                        <img src="/assets/icons/sidenav-version.svg" alt="">
                    </li>
                </ul>
            </div>
        </ng-container>
    </nav>

    <div class="top-nav">
        <div class="flex-center">
            <breadcrumb class="full-width"></breadcrumb>
        </div>
        <div class="flex-center full-width"></div>
        <div class="flex-end">
            <img class="right-logo" src="/assets/images/BM-Logo-Sm.png" alt="">
        </div>
    </div>

    <div #navRoutes class="nav-routes flex-between flex-col">
        <mat-tree *ngIf="!navLoader; else tempDiv" [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node;" matTreeNodePadding
                [class]="node.activate ? 'nav-route-active' : ''">
                <button type="button" *ngIf="node.children && node.children.length" mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <button mat-button type="button" [attr.data-nav-route]="node.route" class="route-button"
                    (click)="changeRoute(node.route, node.urlParam, node.name)">
                    <span>{{ (node.name | translate).toUpperCase() }}</span>
                </button>
            </mat-tree-node>
        </mat-tree>
        <ng-template #tempDiv>
            <div></div>
        </ng-template>

        <ul>
            <li>
                <div class="flex-center">
                    <mat-button-toggle-group name="global-flags"
                        (change)="locale.setLanguage($event.value); toggleNavRoute()">
                        <mat-button-toggle *ngFor="let flag of locale.flags" [value]="flag.langId"
                            [checked]="locale.currentLangId === flag.langId">
                            <img [src]="'/assets/icons/' + flag.flagLogo" [alt]="flag.name + 'Flag'">
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </li>
            <li>
                <button id="tn-notifs" type="button" mat-button (click)="showNotifs()">
                    <span translate class="upper-case">notifications</span>
                </button>
            </li>
            <li>
                <button id="rn-exit" type="button" mat-button (click)="onLogoutClick()">
                    <span translate class="upper-case">exit</span>
                </button>
            </li>
            <li>
                <hr style="width: 70%;">
                <a class="ver-no" (click)="onOpenVerModal()">
                    <span style="margin-inline-start: 8px;" translate>changelog</span>
                    <span class="no-chips">V1.0.16</span>
                </a>
            </li>
        </ul>
    </div>
</ng-container>
<ng-template #otherScreen>
    <div class="top-nav">
        <div>
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <img [src]="'/assets/icons/' + locale.currentFlag.flagLogo" [alt]="locale.currentFlag.name">
            </button>
            <mat-menu #menu="matMenu">
                <div>
                    <ng-container *ngFor="let flag of locale.flags">
                        <div class="fs-option-item">
                            <button mat-menu-item (click)="locale.setLanguage(flag.langId)">
                                <img [src]="'/assets/icons/' + flag.flagLogo" [alt]="flag.name + 'Flag'">
                            </button>
                        </div>
                    </ng-container>
                </div>
            </mat-menu>
        </div>
        <img class="full-width" src="/assets/images/FSG-M.png" alt="FSG-Banner">
    </div>

    <div class="quick-access-menu">
        <div class="other-items" *ngIf="navRouteToggle">
            <ul>
                <li>
                    <a (click)="onClickQuickAccess('walkIn')">
                        <span class="upper-case" translate>walkIn</span>
                        <button mat-icon-button type="button">
                            <mat-icon fontIcon="calendar_today"></mat-icon>
                        </button>
                    </a>
                </li>
                <li>
                    <a (click)="onClickQuickAccess('bookEvent')">
                        <span class="upper-case" translate>bookEvent</span>
                        <button mat-icon-button type="button">
                            <mat-icon fontIcon="calendar_today"></mat-icon>
                        </button>
                    </a>
                </li>
            </ul>
        </div>
        <div class="float-button">
            <button type="button" mat-button (click)="navRouteToggle = !navRouteToggle">
                <mat-icon [fontIcon]="navRouteToggle ? 'close' : 'more_vert'"></mat-icon>
            </button>
        </div>
    </div>

    <nav class="bottom-nav">
        <ul>
            <li class="flex-center" [id]="'sn-' + navItems[0].name"
                [class]="navItems[0].activate ? 'side-nav-active' : 'side-nav-dis'"
                [attr.data-nav-route]="navItems[0].route">
                <button mat-icon-button type="button"
                    (click)="changeRoute(navItems[0].route, navItems[0].urlParam, navItems[0].name)">
                    <img [src]="navItems[0].icon" [alt]="navItems[0].name"></button>
            </li>
            <li class="flex-center" [id]="'sn-' + navItems[1].name"
                [class]="navItems[1].activate ? 'side-nav-active' : 'side-nav-dis'"
                [attr.data-nav-route]="navItems[1].route">
                <button mat-icon-button type="button"
                    (click)="changeRoute(navItems[1].route, navItems[1].urlParam, navItems[1].name)">
                    <img [src]="navItems[1].icon" [alt]="navItems[1].name"></button>
            </li>
            <li class="flex-center" style="position: relative;" id="sn-notifs">
                <span *ngIf="notificationService.notificationCounts > 0" class="notification-badge"
                    [matBadge]="notificationService.notificationCounts" matBadgeSize="small" matBadgeOverlap="false"
                    matBadgeColor="warn"></span>
                <button mat-icon-button type="button" (click)="showNotifs()">
                    <img src="/assets/icons/sidenav-alert.svg" alt="notifications"></button>
            </li>
            <li class="flex-center" id="sn-changes">
                <button mat-icon-button type="button" (click)="onOpenVerModal()">
                    <img src="/assets/icons/sidenav-version.svg" alt="changes"></button>
            </li>
            <li class="flex-center" id="sn-notifications">
                <button mat-icon-button type="button" (click)="onLogoutClick()">
                    <img src="/assets/icons/sidenav-logout.svg" alt="exit"></button>
            </li>
        </ul>
    </nav>
</ng-template>

<div *ngIf="navRouteToggle" class="nav-mask" (click)="toggleNavRoute()"></div>