export enum UserRoles {
  Admin = 1,
  TechAdmin = 2,
  Accounting = 3,
  CompanyAdmin = 4,
}

export enum ColorPalette {
  Primary = '#3369FF',
  Secondary = '#FFC700',
  Info = '#FFFFFF',
  Success = '#009840',
  Warning = '#FFC700',
  Danger = '#E20000',
}
