import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/shared/models/Application.model';
import { ExternalSimulatorMappingPageModel } from 'src/app/shared/models/schedule-intergration/external-simulator/external-simulator-mapping-page.model';
import { ImportEventRequest } from 'src/app/shared/models/schedule-intergration/external-simulator/import-event-request.model';
import { UpdateExternalSimulatorRequest } from 'src/app/shared/models/schedule-intergration/external-simulator/update-external-simulator-request.model';
import { ImportEventReponse } from 'src/app/shared/models/schedule-intergration/import-event/import-event-response';
import { IntegrationTypeResponse } from 'src/app/shared/models/schedule-intergration/integration-type/integration-type-response.model';
import { CreateIntegrationResponse } from 'src/app/shared/models/schedule-intergration/integration/create-integration-response.model';
import { IntegrationResponse } from 'src/app/shared/models/schedule-intergration/integration/integration-response.model';
import { UScheduleIntegrationView } from 'src/app/shared/models/schedule-intergration/uschedule-integration/uschedule-integration-view.model';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService extends HttpService<
  any,
  IntegrationResponse,
  ApiResponse,
  ApiResponse
> {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super(httpClient);

    this._httpClient = httpClient;
  }

  public getAllUScheduleIntegrations(
    companyId: number
  ): Observable<UScheduleIntegrationView[]> {
    const url = this.getUrl + `/Integration/GetAllUSchedules/${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .get<UScheduleIntegrationView[]>(url, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getAllIntegrationTypes(): Observable<IntegrationTypeResponse[]> {
    const url = this.getUrl + `/Integration/GetAllIntegrationTypes`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .get<IntegrationTypeResponse[]>(url, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getAllMappingSimulators(
    companyId: number,
    integrationTypeId: number
  ): Observable<ExternalSimulatorMappingPageModel> {
    const url =
      this.getUrl +
      `/ExternalSimulator/GetAllMappingSimulators?companyId=${companyId}&integrationTypeId=${integrationTypeId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .get<ExternalSimulatorMappingPageModel>(url, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public changeExternalSimulatorLink(
    data: UpdateExternalSimulatorRequest
  ): Observable<boolean> {
    const url = this.getUrl + `/ExternalSimulator/ChangeExternalSimulatorLink`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .post<boolean>(url, data, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public importEvents(data: ImportEventRequest): Observable<ImportEventReponse> {
    const url = this.getUrl + `/ExternalSimulator/ImportEvents`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .post<ImportEventReponse>(url, data, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }
}
