import { ClientType, SignalType } from './Enums';

export class ActiveConnection {
  id?: string;
  simId?: string;
  //  sortId?: string;
  connectionId?: string;
  signaType?: SignalType;
  status?: SignalType;
  type?: ClientType;
  isAlive?: boolean
  notify?: boolean
  constructor(model: ActiveConnection) {
    this.id = model.id || '';
    this.simId = model.simId || '';
    // this.sortId = model.sortId || '';
    this.connectionId = model.connectionId || '';
    this.signaType = model.signaType || SignalType.Unknown;
    this.type = model.type || ClientType.NotProvided;
    this.status = model.status || SignalType.Unknown;
    this.isAlive = true;
    this.notify = model.notify || false
  }
}
