import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';

import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CustomPipesModule } from '../../pipes/custome-pipes.module';
import { HelperService } from '../../services/Helper.service';

@NgModule({
  declarations: [NotificationComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    CustomPipesModule
  ],
  exports: [NotificationComponent],
  providers: [HelperService],
})
export class NotificationModule {}
