import { EventEmitter, Injectable } from '@angular/core';
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core';
import { GlobalLanguageItem } from '../models/Application.model';
import { GlobalLanguages } from '../staticObjects';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private _flags: GlobalLanguageItem[];
  public get flags(): GlobalLanguageItem[] {
    return this._flags;
  }

  private _currentLangId: string;
  public get currentLangId(): string {
    return this._currentLangId;
  }

  public get onChangeLanguage(): EventEmitter<DefaultLangChangeEvent> {
    return this.translate.onDefaultLangChange;
  }

  private _currentFlag: GlobalLanguageItem;
  public get currentFlag(): GlobalLanguageItem {
    return this._currentFlag;
  }

  constructor(private translate: TranslateService) {
    this._flags = GlobalLanguages;
    this._currentFlag = new GlobalLanguageItem({});
    this.checkStorage();
  }

  private checkStorage(): void {
    let value = window.localStorage.getItem(environment.langId);

    if (value?.length) {
      this._currentLangId = value;
      this._currentFlag = this._flags.find((f) => f.langId === value);
      this.translate.setDefaultLang(value);
      this.changeFontFamily(this._currentFlag.fontFamily);
    } else {
      window.localStorage.setItem(
        environment.langId,
        environment.defaultLanguage
      );
      this._currentFlag = this._flags.find(
        (f) => f.langId === environment.defaultLanguage
      );
      this.translate.setDefaultLang(environment.defaultLanguage);
      this.changeFontFamily(environment.defaultFontFamily);
    }
  }

  private changeFontFamily(value: string): void {
    document.body.style.fontFamily = value;
  }

  public setLanguage(value: string): void {
    if (value?.length) {
      this._currentLangId = value;
      this._currentFlag = this._flags.find((f) => f.langId === value);
      window.localStorage.setItem(environment.langId, value);
      this.translate.setDefaultLang(value);
      this.changeFontFamily(this._currentFlag.fontFamily);
      window.location.reload();
    }
  }
}
