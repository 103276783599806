import { EventResponse } from '../Event.model';
import { CompanyResponse } from '../companies.model';

export class CustomerResponse {
  id?: number;
  companyId?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  events?: EventResponse[];
  company?: CompanyResponse;

  constructor(model: CustomerResponse) {
    this.id = model.id || 0;
    this.companyId = model.companyId || 0;
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.phoneNumber = model.phoneNumber || '';
    this.email = model.email || '';
    this.events = model.events || (null as any);
    this.company = model.company || (null as any);
  }
}
