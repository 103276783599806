import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogsSheetComponent } from './logs-sheet.component';
import { LogsSheetService } from './logs-sheet.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CustomPipesModule } from '../../pipes/custome-pipes.module';

@NgModule({
  declarations: [LogsSheetComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    CustomPipesModule,
  ],
  exports: [LogsSheetComponent],
  providers: [LogsSheetService],
})
export class LogsSheetModule {}
