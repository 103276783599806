import { environment } from "src/environments/environment";

export enum SimulatorMenuItemType {
  None = 0,
  TurnOn = 1,
  TurnOff = 2,
  Lock = 3,
  Unlock = 4,
  AssignedUser = 5,
  RemoteControl = 6,
  SimSettings = 7,
  Extend = 8,
  Cancel = 9,
}

export enum ModalType {
  Add = 0,
  Edit = 1,
}

export class SimCardMenuItem {
  id?: number;
  key?: string;
  name?: string;
  icon?: string;
  style?: string;
  forEvent?: boolean;
  type?: SimulatorMenuItemType;
  hasSep?: boolean;
  activate?: boolean;

  constructor(model: SimCardMenuItem) {
    this.id = model.id || 0;
    this.key = model.key || '';
    this.name = model.name || '';
    this.icon = model.icon || '';
    this.style = model.style || '';
    this.forEvent = model.forEvent || false;
    this.type = model.type || SimulatorMenuItemType.None;
    this.hasSep = model.hasSep || false;
    this.activate = model.activate || true;
  }
}

export class NavigationBarItem {
  id?: number;
  name?: string;
  view?: string;
  route?: string;
  urlParam?: string;
  icon?: string;
  forSuperUser?: boolean;
  expandable?: boolean;
  level?: number;
  activate?: boolean;
  children?: NavigationBarItem[];

  constructor(model: NavigationBarItem) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.view = model.view || '';
    this.route = model.route || '';
    this.icon = model.icon || '';
    this.expandable = model.expandable || false;
    this.level = model.level || 0;
    this.forSuperUser = model.forSuperUser || false;
    this.activate = model.activate || false;
    this.children = model.children || (null as any);
  }
}

export class GlobalLanguageItem {
  langId?: string;
  flagLogo?: string;
  name?: string;
  fontFamily?: string;
  isActive?: boolean;

  constructor(model: GlobalLanguageItem) {
    this.langId = model.langId || environment.defaultLanguage;
    this.flagLogo = model.flagLogo || '';
    this.name = model.name || '';
    this.fontFamily = model.fontFamily || environment.defaultFontFamily;
    this.isActive = model.isActive;
  }
}

//-----For version modal
export class AppVersionModal {
  title?: string;
  subTitle?: string;
  content?: AppVersionContent[];

  constructor(model: AppVersionModal) {
    this.title = model.title || '';
    this.subTitle = model.subTitle || '';
    this.content = model.content || (null as any);
  }
}

export class AppVersionContent {
  title?: string;
  deviceName?: string;
  description?: string[];

  constructor(model: AppVersionContent) {
    this.title = model.title || '';
    this.deviceName = model.deviceName || '';
    this.description = model.description || (null as any);
  }
}
//--------------------------

//--------For app configs
export class ElementValueModel {
  name?: string;
  value?: string;

  constructor(model: ElementValueModel) {
    this.name = model.name || '';
    this.value = model.value || '';
  }
}

export class ElementConfigModel {
  background?: string;
  icons?: ElementValueModel[];

  constructor(model: ElementConfigModel) {
    this.background = model.background || '';
    this.icons = model.icons || (null as any);
  }
}

export class TableConfigModel {
  header?: ElementConfigModel;
  body?: ElementConfigModel;

  constructor(model: TableConfigModel) {
    this.header = model.header || (null as any);
    this.body = model.body || (null as any);
  }
}
//--------------------------

export class TimeModel {
  houres?: number;
  minutes?: number;

  constructor(model: TimeModel) {
    this.houres = model.houres || 0;
    this.minutes = model.minutes || 0;
  }
}

export class SelectListItem {
  id?: number;
  name?: string;
  value?: string;
  isActive?: boolean;

  constructor(model: SelectListItem) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.value = model.value || '';
    this.isActive = model.isActive || false;
  }
}

export class QuickAccessResponse {
  name: string;
  data: any;

  constructor(model: QuickAccessResponse) {
    this.name = model.name || '';
    this.data = model.data || (null as any);
  }
}

export class ApiResponse {
  code?: number;
  message?: string;
  data?: any;

  constructor(model: ApiResponse) {
    this.code = model.code || 0;
    this.message = model.message || '';
    this.data = model.data || (null as any);
  }
}
