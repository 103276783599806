import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { EventResponse } from '../models/Event.model';
import { HttpParams } from '@angular/common/http';
import { ScreenSize } from '../enums/screen-size.enum';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CompareDateMode } from '../enums/compare-date-mode.enum';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public offset = moment().utcOffset();

  constructor(
    private snackBar: MatSnackBar,
    private location: Location,
    private translate: TranslateService
  ) {}

  public goBack(): void {
    this.location.back();
  }

  public SortBy(by: string | any, arr: any[], sorted: boolean): boolean {
    arr.sort((a: any, b: any) => {
      if (a[by] < b[by]) {
        return sorted ? 1 : -1;
      }
      if (a[by] > b[by]) {
        return sorted ? -1 : 1;
      }
      return 0;
    });
    return !sorted;
  }

  public Notify(msg: string, action: string, duration: number = 3000): void {
    this.snackBar.open(msg, action, {
      duration: duration,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  public getHoursFromTimeFormat(time: string): number {
    if (time && time.length) {
      let hours = parseInt(time.split(':')[0]) ?? 0;
      return hours;
    }
    return 0;
  }

  public getMinutesFromTimeFormat(time: string): number {
    if (time && time.length) {
      let minutes = parseInt(time.split(':')[1]) ?? 0;
      return minutes;
    }
    return 0;
  }

  public toHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(request).forEach(function (key) {
      httpParams = httpParams.append(key, request[key]);
    });
    return httpParams;
  }

  /**
   * Get time as string or Date from argumant without am pm to
   * string time with am pm with moment.js
   *
   * @param time
   * @returns
   */
  public getTimeFromMoment(time: string | Date = null): string {
    if (time) {
      if (typeof time === 'string') {
        let newTime = new Date();
        newTime.setHours(this.getHoursFromTimeFormat(time));
        newTime.setMinutes(this.getMinutesFromTimeFormat(time));
        return moment(newTime).format('LT');
      }
      return moment(time).format('LT');
    }
    return moment().format('LT');
  }

  public getTime(date: Date): string {
    let h = new Date(date).getHours();
    let m = new Date(date).getMinutes();
    return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
  }

  public isValidDate(dateString: string | any): boolean {
    let reg = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    // First check for the pattern
    dateString = (dateString as String).replace(/-/g, '/');

    if (!reg.test(dateString)) {
      let numArr = (dateString as String).split('/'),
        newDate = [];
      for (let index = numArr.length - 1; index >= 0; index--)
        newDate.push(numArr[index]);
      dateString = newDate.toString().replace(/,/g, '/');
      if (!reg.test(dateString)) return false;
    }

    // Parse the date parts to integers
    var parts = dateString.split('/');
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  public onClear(event: any, control: FormControl): void {
    if (event && event.pointerType) {
      if (event.pointerType === 'mouse' || event.pointerType === 'touch') {
        control.reset();
      }
    } else {
      control.reset();
    }
  }

  public set24TimeFormat(time: string): string {
    if (time && time.length) {
      let timeFormat: string = time
        .toLowerCase()
        .substring(time.length, time.length - 2);

      if (!timeFormat || !timeFormat.length) return time;

      let fixedTime = '';
      let hour = parseInt(time.split(':')[0]),
        min = parseInt(time.split(':')[1]);

      if (timeFormat === 'pm') {
        time = time.toLowerCase().replace('pm', '').trim();
        switch (hour) {
          case 1:
            fixedTime += 13;
            break;
          case 2:
            fixedTime += 14;
            break;
          case 3:
            fixedTime += 15;
            break;
          case 4:
            fixedTime += 16;
            break;
          case 5:
            fixedTime += 17;
            break;
          case 6:
            fixedTime += 18;
            break;
          case 7:
            fixedTime += 19;
            break;
          case 8:
            fixedTime += 20;
            break;
          case 9:
            fixedTime += 21;
            break;
          case 10:
            fixedTime += 22;
            break;
          case 11:
            fixedTime += 23;
            break;
          case 12:
            fixedTime += 12;
            break;
          default:
            break;
        }

        fixedTime += ':' + min;
        return fixedTime;
      } else if (timeFormat === 'am') {
        time = time.toLowerCase().replace('am', '').trim();
        if (hour == 12) hour = 0;
        return hour + ':' + min;
      }
    }

    return time;
  }

  public getDateOnly(date: Date): string {
    let year = date.getFullYear();
    let month = `${
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    }`;
    let day = `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
    return year + '-' + month + '-' + day;
  }

  public formatDate(date: any): any {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  //Date format for mobile browser
  public formatDate2(dateInput: string | any): Date {
    if (dateInput && dateInput.length) {
      dateInput = (dateInput as String).replace(/\//g, '-');

      let dateWS = dateInput;
      let date = dateWS.split(' ')[0].split('-');
      let time = dateWS.split(' ')[1]?.split(':') ?? null;

      return new Date(
        +date[0],
        +date[1] - 1,
        +date[2],
        time[0] && time[0].length ? +time[0] : null,
        time[1] && time[1].length ? +time[1] : null
      );
    }
    return null;
  }

  public searchData(
    value: string,
    destArr: any[],
    searchOnProperty: string
  ): any[] {
    if (value && value.length) {
      let tmpArr = [];
      value = value.toUpperCase();
      for (let index = 0; index < destArr.length; index++) {
        let prop = destArr[index][searchOnProperty];
        let find = prop.toUpperCase().indexOf(value);
        if (find > -1) {
          tmpArr.unshift(destArr[index]);
        }
      }
      return tmpArr;
    } else {
      return destArr;
    }
  }

  public capitalizeWord(value: string): string {
    value = value.trim();
    if (value && value.length) {
      let strArr = value.split(' ');
      for (let index = 0; index < strArr.length; index++) {
        let ch = strArr[index][0];
        strArr[index] =
          ch.toUpperCase() + strArr[index].substring(1, strArr[index].length);
      }
      return strArr.toString().replace(/,/g, ' ');
    }

    return '';
  }

  public convertMinutesToFriendlyString(mins: number): string {
    if (mins >= 60 * 24) return '';

    let h = Math.floor(mins / 60),
      m = mins - h * 60;

    let strTime = '';
    let hasMinutes = m > 0;
    let hasHours = h > 0;

    if (hasHours)
      strTime += `${h} ${
        h > 1 ? this.translate.instant('hours') : this.translate.instant('hour')
      } ${hasMinutes ? '&' : ''}`;

    if (hasMinutes)
      strTime += ` ${m} ${
        m > 1
          ? this.translate.instant('minutes')
          : this.translate.instant('minute')
      }`;

    return strTime;
  }

  public convertJSDateToAPIFormat(date: Date): string {
    let day = date.getDate(),
      month = date.getMonth() + 1,
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds();
    return (
      day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second
    );
  }

  public hasConflictedEvents(
    requestEvent: EventResponse,
    oldEvents: EventResponse[]
  ): string {
    let conflictedEvent: string = '';

    if (!oldEvents || oldEvents.length == 0) return conflictedEvent;
    oldEvents.forEach((currentEvent) => {
      if (
        requestEvent.id !== currentEvent.id &&
        currentEvent.simulatorId == requestEvent.simulatorId
      ) {
        if (
          (new Date(currentEvent.startDateTime) <=
            new Date(requestEvent.startDateTime) &&
            new Date(currentEvent.endDateTime) >=
              new Date(requestEvent.startDateTime)) ||
          (new Date(currentEvent.startDateTime) <=
            new Date(requestEvent.endDateTime) &&
            new Date(currentEvent.endDateTime) >=
              new Date(requestEvent.endDateTime))
        ) {
          return (conflictedEvent = currentEvent.name);
        } else if (
          (new Date(requestEvent.startDateTime) <=
            new Date(currentEvent.startDateTime) &&
            new Date(requestEvent.endDateTime) >=
              new Date(currentEvent.startDateTime)) ||
          (new Date(requestEvent.startDateTime) <=
            new Date(currentEvent.endDateTime) &&
            new Date(requestEvent.endDateTime) >=
              new Date(currentEvent.endDateTime))
        ) {
          return (conflictedEvent = currentEvent.name);
        }
      }
      return conflictedEvent;
    });
    return conflictedEvent;
  }

  public getUTCDateFromOffset(date: Date | string): Date {
    if (typeof date === 'string') date = new Date(date);
    return new Date(moment.utc(date).utcOffset(this.offset).format());
  }

  public eventDurationCalc(startDate: Date, endDate: Date): number {
    let utcSDate = new Date(
        moment.utc(startDate).utcOffset(this.offset).format()
      ),
      utcEDate = new Date(moment.utc(endDate).utcOffset(this.offset).format());

    utcSDate.setSeconds(0);
    utcEDate.setSeconds(0);

    let subtract = utcEDate.getTime() - utcSDate.getTime();
    return subtract / (1000 * 60);
  }

  public cunvertUTCToLocal(datetime, offset) {
    return moment.utc(datetime).utcOffset(this.offset).format();
  }

  public eventHasConflict(
    currentEvent: EventResponse,
    listOfEvents: EventResponse[]
  ): EventResponse {
    let eventConflicted: EventResponse;
    listOfEvents.forEach((event) => {
      if (
        event.simulatorId == currentEvent.simulatorId &&
        new Date(currentEvent.endDateTime) >= new Date(event.startDateTime) &&
        new Date(currentEvent.endDateTime) <= new Date(event.endDateTime)
      ) {
        eventConflicted = event;
      }
    });
    return eventConflicted;
  }

  public screenSize(): number {
    return window.innerWidth;
  }

  public isMobileScreen(): boolean {
    return this.screenSize() <= environment.breakPointScreen.Medium;
  }

  public getDaysList(date: Date, range: number): number[] {
    let days: number[] = [];

    if (range > 0) {
      do {
        range--;

        let d = new Date(date);
        d = new Date(d.setDate(d.getDate() + range));
        days.unshift(d.getDate());
      } while (range !== 0);
    } else {
      while (range !== 0) {
        let d = new Date(date);
        d = new Date(d.setDate(d.getDate() + range));
        days.push(d.getDate());
        range++;
      }
    }

    return days;
  }

  public getDaysOfWeek(locale: string = 'default'): string[] {
    let today = new Date(),
      currentDay = today.getDay(),
      dayCounter = 0;

    today.setDate(today.getDate() - currentDay);

    let days: string[] = [];

    while (dayCounter < 7) {
      dayCounter++;
      days.push(today.toLocaleDateString(locale, { weekday: 'long' }));
      today.setDate(today.getDate() + 1);
    }

    return days;
  }

  public getMonthName(
    date: Date = new Date(),
    locale: string = 'default'
  ): string {
    return date.toLocaleString(locale, { month: 'long' });
  }

  public getMediumDate(
    date: Date = new Date(),
    locale: string = 'default'
  ): string {
    return date.toLocaleString(locale, {
      month: 'short',
      year: 'numeric',
      day: '2-digit',
    });
  }

  public getMonthNameAndYear(
    date: Date = new Date(),
    locale: string = 'default'
  ): string {
    return date.toLocaleString(locale, { month: 'long', year: 'numeric' });
  }

  public assignObj(obj: any): any {
    let target: any = {};
    Object.assign(target, obj);
    return target;
  }

  public removeElement(
    src: Array<any>,
    value: any,
    key: string = 'id'
  ): Array<any> {
    return (src = src?.filter((f) => f[key] !== value));
  }

  public removeElementByIndex(src: Array<any>, index: number): Array<any> {
    return (src = src?.filter((f, i) => i !== index));
  }

  public isValidExtendMinute(event: EventResponse, minutes: number): boolean {
    // event.startDateTime = this.getUTCDateFromOffset(event.startDateTime);
    // event.endDateTime = this.getUTCDateFromOffset(event.endDateTime);

    // let newEndDate = new Date(event.endDateTime);
    // newEndDate.setMinutes(newEndDate.getMinutes() + minutes);

    // if (newEndDate.getDate() !== event.endDateTime.getDate()) return false;

    return true;
  }

  public findBiggerDate(date1: Date, date2: Date): number {
    if (date1 > date2) {
      return 1;
    } else if (date1 < date2) {
      return 2;
    } else {
      return 0;
    }
  }

  public compareDates(
    date1: Date,
    date2: Date,
    compareMode: CompareDateMode = CompareDateMode.Full
  ): number {
    if (compareMode === CompareDateMode.Full) {
      return this.findBiggerDate(date1, date2);
    } else if (compareMode === CompareDateMode.JustDate) {
      let _date1 = new Date(date1),
        _date2 = new Date(date2);

      _date1.setSeconds(0);
      _date2.setSeconds(0);
      _date1.setMinutes(0);
      _date2.setMinutes(0);
      _date1.setHours(0);
      _date2.setHours(0);
      _date1.setMilliseconds(0);
      _date2.setMilliseconds(0);

      return this.findBiggerDate(_date1, _date2);
    } else if (compareMode === CompareDateMode.JustTime) {
      let _date1 = new Date(),
        _date2 = new Date();

      _date1.setSeconds(date1.getSeconds());
      _date2.setSeconds(date2.getSeconds());
      _date1.setMinutes(date1.getMinutes());
      _date2.setMinutes(date2.getMinutes());
      _date1.setHours(date1.getHours());
      _date2.setHours(date2.getHours());
      _date1.setMilliseconds(date1.getMilliseconds());
      _date2.setMilliseconds(date2.getMilliseconds());

      return this.findBiggerDate(_date1, _date2);
    }

    return 0;
  }

  public strUpperFirst(source: string): string {
    return source
      .split(' ')
      ?.map((m) => m.replace(/^./, m[0].toUpperCase()))
      ?.join(' ');
  }
}
