import { CompanyResponse } from './companies.model';
import { LocationResponse } from './location.model';

export class UserResponse {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  pinCode?: string;
  isSuperUser?: boolean;
  avatar?: string;
  logo?: string;
  baymanagerAdmin?: boolean;
  competeAdmin?: boolean;
  companyAdmin?: boolean;
  lastActive?: string;
  lastEmailDateChange?: string;
  cognitoId?: string;
  locationId?: number;
  company?: CompanyResponse;
  location?: LocationResponse;

  constructor(model: UserResponse) {
    this.id = model.id || 0;
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.email = model.email || '';
    this.pinCode = model.pinCode || '';
    this.isSuperUser = model.isSuperUser || false;
    this.avatar = model.avatar || '';
    this.logo = model.logo || '';
    this.baymanagerAdmin = model.baymanagerAdmin;
    this.competeAdmin = model.competeAdmin;
    this.companyAdmin = model.companyAdmin;
    this.lastActive = model.lastActive || '';
    this.lastEmailDateChange = model.lastEmailDateChange || '';
    this.cognitoId = model.cognitoId || '';
    this.locationId = model.locationId || 0;
    this.company = model.company || (null as CompanyResponse);
    this.location = model.location || (null as any);
  }
}

export class UserResponseView {
  id?: number;
  fullName?: string;
  companyName?: string;
  email?: string;
  isSuperUser?: boolean;
  location?: string;

  constructor(model: UserResponseView) {
    this.id = model.id || 0;
    this.fullName = model.fullName || '';
    this.isSuperUser = model.isSuperUser || false;
    this.companyName = model.companyName || '';
    this.email = model.email || '';
    this.location = model.location || '';
  }
}

export class CreateUserRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  pinCode?: string;
  companyId?: number;
  locationId?: number;
  userTypeId?: number;
  isSuperUser?: boolean;
  avatar?: string;
  logo?: string;
  baymanagerAdmin?: boolean;
  competeAdmin?: boolean;
  companyAdmin?: boolean;

  constructor(model: CreateUserRequest) {
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.email = model.email || '';
    this.pinCode = model.pinCode || '';
    this.companyId = model.companyId || 0;
    this.locationId = model.locationId || 0;
    this.userTypeId = model.userTypeId || null;
    this.isSuperUser = model.isSuperUser || false;
    this.avatar = model.avatar || '';
    this.logo = model.logo || '';
    this.baymanagerAdmin = model.baymanagerAdmin;
    this.competeAdmin = model.competeAdmin;
    this.companyAdmin = model.companyAdmin;
  }
}

export class CreateUserResponse {
  id?: number;
  cognitoId?: any;
  error?: string;

  constructor(model: CreateUserResponse) {
    this.id = model.id || 0;
    this.cognitoId = model.cognitoId || (null as any);
    this.error = model.error || '';
  }
}

export class UpdateUserRequest {
  id?: number;
  firstName?: string;
  lastName?: string;
  pinCode?: string;
  companyId?: number;
  locationId?: number;
  isSuperUser?: boolean;
  avatar?: string;
  logo?: string;
  baymanagerAdmin?: boolean;
  competeAdmin?: boolean;
  companyAdmin?: boolean;

  constructor(model: UpdateUserRequest) {
    this.id = model.id || 0;
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.pinCode = model.pinCode || '';
    this.companyId = model.companyId || 0;
    this.locationId = model.locationId || 0;
    this.isSuperUser = model.isSuperUser || false;
    this.avatar = model.avatar || '';
    this.logo = model.logo || '';
    this.baymanagerAdmin = model.baymanagerAdmin;
    this.competeAdmin = model.competeAdmin;
    this.companyAdmin = model.companyAdmin;
  }
}

export class DeleteUserRequest {
  id?: number;

  constructor(model: DeleteUserRequest) {
    this.id = model.id || 0;
  }
}

// export class ListUserResponseSU {
//   data?: UserResponse[];
//   page_options?: PageOptions;

//   constructor(model: ListUserResponseSU) {
//     this.data = model.data || (null as Array<UserResponse>);
//     this.page_options = model.page_options || (null as PageOptions);
//   }
// }
