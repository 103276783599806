import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth } from 'aws-amplify';
import {
  CognitoUser,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { removeStorageItemsByExclude } from '../helper-functions';
import { Subject } from 'rxjs';

export enum ValidationStatus {
  Invalid = 0,
  Valid = 1,
  Auth = 2,
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public wrongCredentials = false;
  tokenHelper = new JwtHelperService();

  private validationSub = new Subject<ValidationStatus>();
  public validationSub$ = this.validationSub.asObservable();

  private _validationResult: ValidationStatus;
  public get validationResult(): ValidationStatus {
    return this._validationResult;
  }
  public set validationResult(v: ValidationStatus) {
    this._validationResult = v;
    this.validationSub.next(v);
  }

  constructor() {
    this._validationResult = ValidationStatus.Auth;
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      return false;
    }
  }

  async isTokenValid(): Promise<boolean> {
    try {
      var isValid = (await Auth.currentSession()).isValid();
      return isValid;
    } catch (err) {
      return false;
    }
  }

  async refreshToken(): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();
      var idToken = user.getSignInUserSession().getIdToken();
      user.refreshSession(
        user.getSignInUserSession().getRefreshToken(),
        this.tokenRefreshed
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  async getTokenExpiration(): Promise<Date> {
    try {
      const user = await this.getCurrentUser();
      var unix_timestamp = user
        .getSignInUserSession()
        .getIdToken()
        .getExpiration();
      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      var date = new Date(unix_timestamp * 1000);
      return date;
    } catch (err) {
      return null;
    }
  }

  async getUserId(): Promise<string> {
    try {
      const attr = await this.getUserInfo();
      var idToken = attr.username;
      return idToken;
    } catch (err) {
      //console.log(err);
      return null;
    }
  }

  async getIdToken(): Promise<CognitoIdToken> {
    try {
      const user = await this.getCurrentUser();
      var idToken = user.getSignInUserSession().getIdToken();
      return idToken;
    } catch (err) {
      return null;
    }
  }

  async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession();
  }

  async getCurrentUser(): Promise<CognitoUser> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (err) {
      return null;
    }
  }

  async getUserInfo(): Promise<any> {
    try {
      const res = await Auth.currentUserInfo();
      return res;
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }
  async getUserAttributebyKey(key: string): Promise<string> {
    try {
      let currentUserInfo = await Auth.currentUserInfo();
      let value = currentUserInfo.attributes[key];
      return value;
    } catch (err) {
      console.log('error fetching user info: ', err);
      return null;
    }
  }
  async signIn(userName, password) {
    try {
      return Auth.signIn(userName, password)
        .then((user) => {
          if (
            user.challengeName &&
            user.challengeName === 'NEW_PASSWORD_REQUIRED'
          ) {
            return -1;
          } else if (
            user &&
            user.attributes &&
            user.attributes['custom:company_id'] &&
            parseInt(user.attributes['custom:company_id']) > 0
          ) {
            // user exists
            return 1;
          } else {
            // user doenst exist
            return -2;
          }
        })
        .catch((e) => {
          console.log(e);
          return 0;
        });
    } catch (err) {
      console.log('Current user info failed to fetch', err);
      return 0;
    }
  }
  async completeNewPassword(userName, code, password) {
    return Auth.signIn(userName, code)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          return Auth.completeNewPassword(
            user, // the Cognito User Object
            password // the new password
          )
            .then((res) => {
              return true;
            })
            .catch((e) => {
              console.log(e);
              return false;
            });
        } else {
          // at this time the user is logged in if no MFA required

          console.log(user);
          return true;
          // other situations
        }
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }
  async changePassword(userName, oldPassword, newPassword) {
    try {
      const res = await Auth.changePassword(userName, oldPassword, newPassword);
      return res;
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  async forgotPassword(userName) {
    try {
      const res = await Auth.forgotPassword(userName);
      return res;
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  async forgotPasswordSubmit(userName, confirmationCode, password) {
    try {
      const res = await Auth.forgotPasswordSubmit(
        userName,
        confirmationCode,
        password
      );
      return res;
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  public async getCognitoGroups(): Promise<Array<string>> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken()?.payload['cognito:groups'];

      return groups;
    } catch (error) {
      return null;
    }
  }

  async isAuthenticatedCognitoGroups(): Promise<boolean> {
    try {
      return true;

      const groups = await this.getCognitoGroups();
      console.log('Groups : ', groups);

      if (groups?.length) {
        const adminGroups = groups.find(
          (f) => f.toLowerCase() === 'baymanageradmin'
        );

        if (adminGroups?.length) {
          return true;
        }
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  async signOut() {
    try {
      await Auth.signOut({ global: false });
      //window.localStorage.removeItem(environment.navName);
      //window.localStorage.removeItem(environment.bayManagerUser);
      //window.localStorage.removeItem(environment.calendarState);
      //window.localStorage.removeItem('Bay-Manager-Notifs');
      //window.localStorage.removeItem('_select_locs');
      //localStorage.removeItem('locationsandsims');
      //location.reload();
    } catch (error) {
      //localStorage.clear();
      //window.localStorage.removeItem(environment.navName);
      //window.localStorage.removeItem(environment.bayManagerUser);
      //window.localStorage.removeItem(environment.calendarState);
      //window.localStorage.removeItem('Bay-Manager-Notifs');
      //window.localStorage.removeItem('_select_locs');
      //localStorage.removeItem('locationsandsims');
      console.error(error);
    }
  }

  public async unautorized(excludeStorageKeys?: string[]): Promise<void> {
    try {
      await this.signOut();
    } catch (error) {
      console.log(error);
    } finally {
      this.validationResult = ValidationStatus.Invalid;
      removeStorageItemsByExclude(excludeStorageKeys);
      //window.location.href = '/login';
    }
  }

  private tokenRefreshed(e, r) {
    var idToken = r.getIdToken();
  }
}
