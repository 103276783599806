const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KWjsmZvMg",
    "aws_user_pools_web_client_id": "tnfn4vuel4cgep41miqllbuqn",
    "oauth": {
        "domain": "baymanageridp.fullswingapps.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://baymanager.fullswingapps.com/login/",
        "redirectSignOut": "https://baymanager.fullswingapps.com/login",
        "responseType": "code"
    }
};

export default awsmobile;
