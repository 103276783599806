import {
  AppVersionModal,
  GlobalLanguageItem,
  NavigationBarItem,
  SelectListItem,
  SimCardMenuItem,
  SimulatorMenuItemType,
} from './models/Application.model';

export const MODAL_DATA = new Array<AppVersionModal>(
  {
    title: 'V1.0.16',
    subTitle: 'March 20, 2024',
    content: [],
  },
  {
    title: 'V1.0.15',
    subTitle: 'Jan 29, 2024',
    content: [],
  },
  {
    title: 'V1.0.14',
    subTitle: 'Sep 07, 2023',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Staff management tab',
        description: [
          'After a staff member had been added from the Bay Manager site they couldn’t login with the given temporary password',
          'Deleted customer could be searched for using the search bar before refreshing the page',
          'The location dropdown was nonfunctional when adding or editing a staff member',
          'The Location column showed company instead of location ',
        ],
      },
      {
        deviceName: 'Event Management',
        description: [
          'Editing an event in the list view will cause the event to display incorrectly',
          'Extending a session into an existing session did not display an error message',
          'Extending event time sent time extension signal if it conflicts with other events',
          'Event Creation - Events could be created for past dates and times',
          'Event names would overlap with other dates in the month view',
          'Event Messaging - Event was incorrectly referenced when it ends',
          'Responding to an extension request did not update in separate tabs until the user refreshes',
        ],
      },
      {
        deviceName:
          'Password Reset - The user was unable to scroll down on the password reset page',
      },
      {
        deviceName:
          'Sim Licensing - A sim did not state its license was expired when viewed on the simulator`s page',
      },
    ],
  },
  {
    title: 'V1.0.13',
    subTitle: 'Aug 07, 2023',
    content: [],
  },
  {
    title: 'V1.0.12',
    subTitle: 'July 22, 2023',
    content: [],
  },
  {
    title: 'V1.0.11',
    subTitle: 'June 01, 2023',
    content: [],
  },
  {
    title: 'V1.0.10',
    subTitle: 'Mar 27, 2023',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'FS Bay Manager Website',
      },
      {
        deviceName: 'On Safari (iPhone 12)',
        description: [
          'The sidebar was cut off on the bottom of the screen, and was not scrollable',
          'The "Change Password" button was cut off by bottom bar',
        ],
      },
      {
        deviceName: 'iPad',
        description: [
          'When clicking on an event in the list view, the last event created was the one that popped up',
          "User's page items per page distorted view and was unable to change the items per page again",
          'When calendar took up majority of screen there was no way to scroll around the calendar. Just created event.',
        ],
      },
      {
        deviceName: 'Login Page',
        description: [
          'Clicking the link to reset a password for would not work if the user was previously logged in to Bay Manager',
        ],
      },
      {
        deviceName: 'Session Extension Requests ',
        description: [
          'Requests from launcher were not extending when accepted from iPad or website',
        ],
      },
      {
        deviceName: 'Simulator Management Tab',
        description: [
          'Sims were showing as offline even though simulators where connected to the internet and logged into FS Launcher',
        ],
      },
      {
        deviceName: 'User Management tab ',
        description: [
          'A new user could not be deleted until the page is refreshed',
          'Clicking on a new user to edit it did not function correctly',
          "A user's pin could be changed to nothing and be saved",
        ],
      },
      {
        deviceName: 'Notification Icon',
        description: [
          'Dismissing notifications did not update the unread number',
          'Session extension notification would only reference the event name and not the sim as well',
          "The notification pop-up contained two misplaced 'null' texts",
        ],
      },
      {
        deviceName: 'Event Management',
        description: [
          'The user could enter more than the maximum number of characters in the event message',
          'Booking Calendar - End of year did not allow you to schedule events for the next year',
          'Calendar booking issue with events created in February.',
        ],
      },
      {
        deviceName: 'Release Notes Icon ',
        description: [
          'The current site version in the sidebar did not reflect the most updated change log version',
        ],
      },
      {
        deviceName: 'FS Launcher Application v1.0.46',
      },
      {
        deviceName: 'Admin Panel',
        description: [
          'PIN code did not always recognize',
          'Admin Pin was requiring a relaunch in order to function',
          'A user could access the admin menu from the lock screen if they logged in then logged out',
        ],
      },
      {
        deviceName:
          'FS Golf Performance issue when using Bay Manager - FSG appeared to lag when using FSL and FSO',
      },
      {
        deviceName:
          'FS Launcher did not give a message when session extension was denied',
      },
      {
        deviceName: 'Login ',
        description: [
          'Any login error would display a generic error message instead of describing the error',
          'Reset password link sent to email would open Bay Manager site and not display temporary password information',
          'Reset password link from Launcher sent to email opened Bay Manager site',
        ],
      },
      {
        deviceName:
          'The assistance menu displayed the wrong date and time when opened before logging in',
      },
      {
        deviceName:
          'Overlay process check on session event received to ensure session timer is able to reopen if closed inadvertently',
      },
      {
        deviceName: 'Games Library',
        description: [
          'Topgolf Swing Suite had no game detail information in the game launch pop up',
          'Carnival had a missing icon in the library and in the game info pop up',
          'Game Info Pop up - Carnival was spelled incorrectly',
          'Game Info Pop up - Missing period in the Target Golf description',
          'Game Info Pop up - A word was incorrectly capitalized in the Baseball description',
          'Game Info Pop up - A word was incorrectly capitalized in the Soccer description',
          'Games Library - Carnival Icon was not displaying',
        ],
      },
      {
        deviceName:
          'Connection with Bay Manager would occasionally fail if the connection was temporarily interrupted',
      },
      {
        deviceName:
          "The 'New End Time' displayed an incorrectly formatted time",
      },
      {
        deviceName:
          'Launcher did not display a message when session extension was denied',
      },
      {
        deviceName: 'Games Library Games Info Popup',
        description: [
          'The game icon would appear white, and all text would disappear when rapidly clicking in and out of a game icon',
        ],
      },
      {
        deviceName: 'FS Updater',
        description: [
          'Opening updater through Launcher admin menu took too long and allowed for multiple instances to be opened',
          'If FS Updater was closed, then FS Updater would not re-open. FS Updater will now re-open 5 minutes if closed',
        ],
      },
      {
        deviceName:
          'Closing FS Launcher would cause FS Launcher to be non-responsive when re-opened',
      },
      {
        deviceName: 'On-screen Keyboard',
        description: [
          'Background images would bleed through onscreen keyboard',
          'Did not appear for the Registration page',
          'Did not appear for the Recovery page',
          'Did not have shift functionality for letters',
          'On first launch had some letters capitalized and some were not',
        ],
      },
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
      },
      {
        deviceName: 'Sim connection improvements',
        description: [
          'Ability to re-establish sim connection with website',
          'Added parameters to scale reconnecting using interval and timeout',
          'Bay Manager requests for acknowledgment after launcher receives an event',
          "Add logic to support ping/pong in tablets when it's on sleep/low battery mode",
        ],
      },
      {
        deviceName: 'FS Launcher ',
      },
      {
        deviceName:
          'FS Updater can now display available updates when FS Launcher is opened when PC boots',
      },
      {
        deviceName: 'Admin Panel',
        description: [
          'Added an account info panel to the admin menu for commercial and Bay Manager modes that requires PIN',
          'Added a popup message that states a logout has happened on success',
          'Added a popup stating your already logged out if a logout is attempted while not logged in',
          'Made the PIN number locally managed to prevent the unrecognized PIN code error message',
        ],
      },
      {
        deviceName: 'Session Timer',
        description: [
          'Overlay process check on session event received to ensure session timer is able to reopen if closed inadvertently',
        ],
      },
      {
        deviceName: 'Onscreen Keyboard',
        description: [
          'Added an screen keyboard which will display when selecting text entry fields for FS Launcher login screens',
        ],
      },
      {
        deviceName: 'Welcome Screen ',
        description: [
          'Changed to a single click on the screen to access the Hidden Admin Panel',
          'Added a “launched app” infographic in launcher for the current launched known app',
        ],
      },
      {
        deviceName: 'Games Library',
        description: [
          'Added closing game message pop up to help prevent users from opening multiple games simultaneously',
          'Removed FSG Trial version from commercial and bay manager environments',
          'Removed user guide section until content is available',
          'Added closing game message pop up notification to prevent users from opening multiple games simultaneously',
          'Added a multiple running apps process management task window to identify and resolve issue when multiple games are opened/running simultaneously',
        ],
      },
      {
        deviceName:
          'Offline mode so users can access the Games Library if there is no internet connection present',
      },
    ],
  },
  {
    title: 'V1.0.9',
    subTitle: 'Dec 22, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The button to delete a user in the user list was nonfunctional',
          "Adding a user with an email already in the database would display an infinite 'Loading' widget",
          'Safari Browser - Was unable to log out of Bay Manager',
        ],
      },
      {
        deviceName: 'FS Launcher Application',
        description: [
          'Game Info Pop up - Basketball had an incorrectly capitalized A.',
          'Game Info Pop up - Soccer had an incorrectly capitalized Or.',
          'Game Info Pop up - Hockey had incorrect capitalizations.',
          'Game Info Pop up - Rugby had overlapping words in the info pop up.',
          "Game Info Pop up - Zombie Dodgeball had a sentence that doesn't make sense.",
          'Launcher put out different Login errors.',
          'An application would open in the background and the user could not access it when clicking on any other game icon while the first game was loading',
          'Finalizing First Time Setup to the Commercial BM environment did not automatically open Overlay',
          'Locking Launcher during an active event would stop that event from affecting Launcher',
          'The Guide page text would remain on the screen when an event starts',
        ],
      },
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The extra session request notification was misaligned when viewed in the notifications area of the Sim page',
          'Added Ping/Pong signal to Bay manager and DAL to keep connections active',
        ],
      },
    ],
  },
  {
    title: 'V1.0.8',
    subTitle: 'Dec 22, 2022',
    content: [
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The extra session request notification was misaligned when viewed in the notifications area of the Sim page',
          'New UI modifications',
        ],
      },
    ],
  },
  {
    title: 'V1.0.7',
    subTitle: 'Oct 28, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The Simulator page did not properly load the first time the site is opened',
          "Pressing the 'Back' arrow while on the login page would display a black screen",
          "The user did not receive a notification when a Simulator's license becomes invalid",
          'Event end "Success" message would display the event name as \'undefined\'',
          'Refreshing the site would cause any assistance notifications to be removed',
        ],
      },
      {
        title: 'IMPROVEMENTS',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The extra session request notification was misaligned when viewed in the notifications area of the Sim page',
        ],
      },
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: ['New UI modifications'],
      },
    ],
  },
  {
    title: 'V1.0.6',
    subTitle: 'Oct 10, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'Fixed issue where the user did not receive a notification when a Simulator’s license became invalid',
          'Fixed issue when session extension notifications could be approved after its event ends and would extend the ended event',
          'Fixed issue where extending a session through Bay Manager would not update the actual event time in Launcher',
          'Fixed issue when refreshing the site would cause any assistance notifications to be removed',
          'Fixed issue when clicking on notification from Microsoft Edge for session extension brings you to a page with an error.',
          'Fixed issue when assistance menu notification did not show up on Bay Manager website unless page was refreshed.',
          'Fixed issue when starting an event with the FSL unlocked canceled event on website but FSO still displayed event timer',
          'Fixed issue with (Safari/iOS) browsers. There was no functionality when tapping on a calendar date to create an event',
          'Fixed issue when editing date, start, or end times would not save until refreshing the page when the user edits them right after creating an event',
          'Fixed issue when clicking to edit an event in month view when using the "+X more" list widget would overlap with the \'Edit Event\' widget',
          'Fixed issue when controlling two simulators at once from Bay Manager website with the same login, connection was lost to both.',
          'Fixed issue when simulator showed as offline if left idle for too long.',
          'Fixed issue with (iPad) notifications for session extension did not show up when the notifications icon is selected.',
        ],
      },
      {
        title: 'IMPROVEMENTS',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The extra session request notification was misaligned when viewed in the notifications area of the Sim page',
          'Force quit Launcher did not delete event.',
        ],
      },
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'User Page & User Table New Design',
          'Add API endpoint to save user’s pin code from launcher',
          'New UI modifications',
        ],
      },
    ],
  },
  {
    title: 'V1.0.1',
    subTitle: 'Aug 15, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'A duplicate session extension notification would be created when the user extends an active event and refreshes the page',
          'Session extension notifications would not automatically remove themselves from the Simulator page when its event ends',
          'The user was able to create conflicting events when refreshing the page after attempting to create a conflicting event',
          'Could create a new book in event as same time as existing event. Also didn’t update Launcher time as a result',
          'Sim timer desync when focused on different tabs and returning to Bay Manager',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0',
    subTitle: 'Aug 01, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'Fixed issue where the "Bay Manager" logo could be seen behind the release notes when scrolling in the change log window.',
          'Fixed issue where the changelog window was briefly expanded when first opening it.',
          'Fixed issue where simulator session timer began with more time than the event was created with when an event started.',
          'Fixed issue where the user could scroll down past the Start button to the login section when loading the login page.',
          'Fixed issue where the previous location selected would not be remembered when refreshing the Simulator page.',
          'Fixed issue when you hover over the Month button and the List button it will showed "MONTH view" and "LIST view".',
          'Fixed issue with the Simulators page If the name of a Sim was too big it would overlap with the 3 dot menu.',
          'Fixed issue with Safari browser when extending a time of an event, the session end time did not show properly.',
          'Fixed issue with Safari browser for iPads were unable to unlock/lock a sim properly.',
          'Fixed issue where the user was prompted to reset their password when logging in with Admin Utility credentials.',
          'Fixed issue where the "Lock" option for a Sim was not capitalized.',
          'Fixed issue where user was able to select Lock and Unlock options for a Sim when it is offline.',
          'Fixed issue where error messages displayed inconsistently.',
          'Fixed issue where adding an event with the walk in button in simulators tab did not show up in the simid unless the page was refreshed.',
          'Fixed issue when deleting an event didn’t update the Launcher, Session Timer continued to count down.',
          'Fixed issue where event still ran on Bay Manager when launcher on Sim closed.',
          'Fixed issue with no error message would display for an incorrect user account.',
          'Fixed issue where countdown time in simulators tab should have leading 0 for single digits.',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'When a session ended FS Launcher did not close FS Golf.',
          'Multiple game instances of a game could be loaded when the user attempted to play a game multiple times',
          'Session did not start until the user relaunched FS Launcher when an active event started',
          'The "New End Time" of the "Session Extension" option was incorrect when using the "Assistance" menu',
          'User could not click on "Submit" button when trying to send a session extension request',
          'Starting Launcher, the user would briefly see the Games Library when the user is automatically logged in',
          'There is was no User Account menu tab logged into Commercial Bay Manager FSL',
          'The "?" icon that opens the Help & Documentation window was gone when reaching the login page of any FSL environment',
          'Multiple texts remained on guide menu',
          'When Showdown opened from Launcher a pop up saying the game is closed showed before the game actually opened',
        ],
      },
      {
        title: 'FEATURES',
        deviceName: 'Bay Manager Website',
        description: [
          'Added logic to cancel an event session on BM from launcher',
          'Updated Email Template look and feel',
          'On the Event List page, added columns with further details to improve visual feel',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: ['Assistance menu changes for user ease'],
      },
    ],
  },
  {
    title: 'V1.0.0-5',
    subTitle: 'Jul 12, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'Clicking on a calendar date does not automatically select the date',
          'Error message has a spelling error when incorrectly resetting a password',
          'Newly created events are not showing up in the calendar or list view for the user to view or edit them',
          'Pressing "Enter" while in the "Name" input field during event creation will clear any input',
          'Safari Browser - The "Change Password" button could not be interacted with',
          'Safari Browser - Refreshing the page would log out the user',
          'User is able to login to Bay Manager using Admin Utility credentials',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'Resolved issue were you were unable to click on the assistance menu items',
          'Resolved issue when you logged out of you user profile and re-open FS Launcher, the login window automatically logged you back into your account',
          'Resolved issue when selecting logout from the hidden admin menu did not redirect you back to the login page',
          'Resolved issue when launcher was not locking or unlocking through the Bay Manager website',
          'Resolved issue after first time setup the login menu didn’t appear',
          'Resolved issue were you could not submit an assistance request',
          'Resolved issue with remembered login resetting after a subsequent auto login',
          'Resolved issue in the Games Library where E6 Launching causes FS Launcher to hang',
          'Resolved issue in Games Library where Showdown Golf was not launching',
          'Resolved issue where uninstalled games display in their original position when viewed in the games library',
          'Resolved issue with E6 and Showdown would not display on certain simulators even when installed',
          'Resolved issue where there was no functionality to uncheck "Stay Logged In" when logging into FS Launcher Residential',
          'Resolved issue with uninstalled game icons were nearly blacked out entirely when viewed on a widescreen sim',
          'Resolved issue with certain installed games would not be registered as being installed',
          'Resolved issue with game icon display for 4:3 sims',
          'Games Library fixes',
        ],
      },
      {
        title: 'FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'Deleting and canceling an event on FS Bay Manager website will Remove Session Timer in FS Launcher application',
          'Hide lock & unlock options in Simulators Management simulator menu when there is a session running',
          'Session extension and cancelling options during an active session',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'Ability to cancel events once they have started - Subscribing to cancellation message',
          'Added a lockout button in admin menu',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0-4',
    subTitle: 'Jun 21, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'New fonts on BM not showing on Staging.',
          'Sim Management tab - Session Extension - cannot extend active session at the last minute of time',
          'Pressing the `Back` arrow while on the login page will display a black screen',
          'An event that is just starting will not show underneath the respective simulator until the page is refreshed',
          'When creating an event drop down menu`s for date and times are nonfunctional - Safari browser',
          'The user is able to input `1` or `2` to set the Start or End time to AM or PM respectively when adding an event',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0-3',
    subTitle: 'May 31, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'Adjusted the layout of the simulators page on iPad',
          'Certain simulators would not show as online in the simulators tab after successfully authenticating in FS Launcher',
          'Safari Browser - Login video did not play before going to the login page',
          'Safari Browser - Horizontal view had large white borders on the left, right, and bottom of the page',
          'Safari Browser - The "Change Password" button could not be interacted with',
          'Safari Browser - Refreshing the page would log out the user',
          'Safari Browser - Was unable to log out of Bay Manager website',
        ],
      },
      {
        title: 'FEATURES',
        deviceName: 'Bay Manager Website',
        description: [
          'Simulators Management Tab - License renewal popup menu to renew sim license added',
          'Simulators Management Tab - Ability to extend active event time',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0-2',
    subTitle: 'May 17, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'Start or end time shows as "Invalid DateTime" when attempting to create an event on mobile',
          'User cannot create events when manually entering a start or end time',
          'The "End Time" of an event will say "Invalid DateTime" when using the "+30 min" or "+1 hr" buttons in the "Book Event" page',
          'Certain simulators will not show as online in the simulators tab after successfully authenticating in Launcher',
          'Assistance notification does not reference the sim or event',
          'Safari Browser - Creating or editing events will not update',
          'Safari - browserAdding event does not work',
          'Notification for Sim going online or offline notification displays incorrect sim bay ',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'Uninstalled games display in their original position when viewed in the games library',
          'E6 and Showdown will not display on certain simulators even when installed',
          'There is no functionality to uncheck "Stay Logged In" when logging into FS Launcher Residential',
          'Uninstalled game icons are nearly blacked out entirely when viewed on a widescreen sim',
          'Certain installed games will not be registered as being installed',
          'Game icons display issues for 4:3 sims',
          "When overlay app closes unexpectedly/manual during a launcher session, launcher doesn't return to a locked state",
        ],
      },
      {
        title: 'FEATURES',
        deviceName: 'Bay Manager Website',
        description: [
          'Ability to cancel active events from the Simulators Management tab',
          'iPad - Google Chrome support',
          'Booking clock colors adjustments',
          'Super User Accounts - ability to add additional user accounts',
          'Added a Walk In button on the Simulators page',
          'Added new video to welcome page',
          'Ability to manage sims with multiple user accounts',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'Games library - Only display games titles which are licensed ',
          'Added a lockout button option in the hidden admin menu',
          'Added standard login module for commercial operators',
          'Call to database for commercial user PIN for ability to manage hidden admin PIN number from Bay Manager website',
          'Home screen improvements to UI look and feel',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0-1',
    subTitle: 'May 03, 2022',
    content: [
      {
        title: 'BUG FIXES',
        description: [
          '"Login Failed" spelling issue.',
          'Clicking to edit an event in month view when using the "+X more" list widget will overlap with the "Edit Event" widget',
          'User can add an event with a start date year of 5 digits or more',
        ],
      },
      {
        title: 'TASK',
        description: [
          'Add check inside DAL SignIn method for a passed user email against the companies if user email exists in a company',
          'Versioning section',
        ],
      },
    ],
  }
);

export const MenuItems: SimCardMenuItem[] = [
  // {
  //   id: 1,
  //   name: 'Turn On',
  //   icon: 'power_settings_new',
  //   type: SimulatorMenuItemType.TurnOn,
  //   activate: true,
  //   hasSep: false,
  //   style: '',
  // },
  // {
  //   id: 2,
  //   name: 'Turn Off',
  //   icon: 'power_settings_new',
  //   type: SimulatorMenuItemType.TurnOff,
  //   activate: true,
  //   hasSep: false,
  //   style: 'transform: rotate(180deg);',
  // },
  {
    id: 3,
    key: 'lock',
    name: 'Lock',
    icon: 'lock',
    forEvent: false,
    type: SimulatorMenuItemType.Lock,
    activate: true,
    hasSep: false,
    style: '',
  },
  {
    id: 4,
    key: "unlock",
    name: 'Unlock',
    icon: 'lock_open',
    forEvent: false,
    type: SimulatorMenuItemType.Unlock,
    activate: true,
    hasSep: false,
    style: '',
  },
  // {
  //   id: 5,
  //   name: 'Assigned User',
  //   icon: 'account_circle',
  //   type: SimulatorMenuItemType.AssignedUser,
  //   activate: true,
  //   hasSep: true,
  //   style: '',
  // },
  {
    id: 6,
    key: 'remoteControl',
    name: 'Remote Control',
    icon: 'settings_remote',
    forEvent: false,
    type: SimulatorMenuItemType.RemoteControl,
    activate: false,
    hasSep: false,
    style: '',
  },
  {
    id: 7,
    key: 'simSettings',
    name: 'Sim Settings',
    icon: 'settings',
    forEvent: false,
    type: SimulatorMenuItemType.SimSettings,
    activate: false,
    hasSep: true,
    style: '',
  },
  {
    id: 8,
    key: 'extend',
    name: 'Extend',
    icon: 'playlist_add',
    forEvent: true,
    type: SimulatorMenuItemType.Extend,
    activate: true,
    hasSep: false,
    style: '',
  },
  {
    id: 9,
    key: 'cancel',
    name: 'Cancel',
    icon: 'cancel',
    forEvent: true,
    type: SimulatorMenuItemType.Cancel,
    activate: true,
    hasSep: false,
    style: '',
  },
];

export const Events_Color: string[] = [
  '#006dc3',
  '#0025c3',
  '#8600c3',
  '#c300c1',
  '#c30067',
  '#c30000',
  '#c37a00',
  '#97c300',
  '#00c341',
  '#00c3b6',
  '#2e7000',
  '#007063',
  '#004470',
  '#3d0070',
  '#70006a',
  '#700000',
  '#62604b',
  '#4d624b',
  '#5e5e5e',
  '#878787',
];

export const NavItems: NavigationBarItem[] = [
  {
    id: 2,
    name: 'event',
    view: 'Event',
    icon: `/assets/icons/sidenav-events.svg`,
    urlParam: '',
    route: 'event',
    expandable: false,
    level: 1,
    forSuperUser: false,
    activate: false,
  },
  {
    id: 3,
    name: 'simulators',
    view: 'Simulators',
    icon: `/assets/icons/sidenav-simulators2.svg`,
    route: 'simulators',
    urlParam: '',
    expandable: false,
    level: 1,
    forSuperUser: false,
    activate: false,
  },
  {
    id: 4,
    name: 'staff',
    view: 'Staff',
    icon: `/assets/icons/sidenav-staff.svg`,
    route: 'staff',
    urlParam: '',
    expandable: false,
    level: 1,
    forSuperUser: true,
    activate: false,
  },
  {
    id: 5,
    name: 'customer',
    view: 'Customer',
    icon: '/assets/icons/sidenav-customer.svg',
    route: 'customer',
    urlParam: '',
    expandable: false,
    level: 1,
    forSuperUser: false,
    activate: false,
  },
  {
    id: 6,
    name: 'archive',
    view: 'Archive',
    icon: '/assets/icons/sidenav-archive.svg',
    route: 'archive',
    urlParam: '',
    expandable: false,
    level: 1,
    forSuperUser: false,
    activate: false,
  },
  {
    id: 7,
    name: 'integration',
    view: 'Integration',
    icon: '/assets/icons/sidenav-integration.svg',
    route: 'integration',
    urlParam: '',
    expandable: false,
    level: 1,
    forSuperUser: false,
    activate: false,
  },
];

export const GlobalLanguages: GlobalLanguageItem[] = [
  {
    flagLogo: 'flag-usa.svg',
    isActive: true,
    langId: 'en',
    name: 'USA',
    fontFamily: 'DavisSans',
  },
  {
    flagLogo: 'flag-japan.svg',
    isActive: true,
    langId: 'ja',
    name: 'Japan',
    fontFamily: 'NotoSansJP',
  },
];
