import { Injectable } from '@angular/core';
import { EventService } from '../../event.service';
import { NotificationService } from 'src/app/shared/modules/notification/notification.service';
import { HelperService } from '../../Helper.service';
import { BehaviorSubject } from 'rxjs';
import {
  EventsWorkerConfigObj,
  EventsWorkerStatus,
  UpcomingEvents,
} from 'src/app/shared/models/Event.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class EventWorkerService {
  eventsWorker: Worker;

  private _eventWorkerStatus: BehaviorSubject<EventsWorkerConfigObj>;
  public get getEventWorkerStatus$(): BehaviorSubject<EventsWorkerConfigObj> {
    return this._eventWorkerStatus;
  }

  constructor(
    private eventService: EventService,
    public notificatrionService: NotificationService,
    private helper: HelperService,
    private translate: TranslateService
  ) {
    this._eventWorkerStatus = new BehaviorSubject<EventsWorkerConfigObj>(
      null as any
    );
  }

  initialWorker(): void {
    if (typeof Worker !== 'undefined') {
      this.eventsWorker = new Worker(
        new URL('./event-worker.worker', import.meta.url)
      );
      this.eventsWorker.onmessage = ({ data }) => {
        if (typeof data !== 'undefined' && data.currentEvent) {
          let event = this.eventService.jsonParseUpcomingEvent(
            data.currentEvent
          );
          if (event && event.id) {
            if (data.status === 'Start') {
              this._eventWorkerStatus.next({
                status: EventsWorkerStatus.Start,
                currentEvent: event,
              });
            } else if (data.status === 'End') {
              this.terminateEvent(event);
              this.resetWorker();
              this._eventWorkerStatus.next({
                status: EventsWorkerStatus.End,
                currentEvent: event,
              });
            } else if (data.status === 'Grow') {
              this._eventWorkerStatus.next({
                status: EventsWorkerStatus.Grow,
                currentEvent: event,
              });
            }
          }
        }
      };
    } else {
      console.error('Worker error. Browser not supported.');
    }
  }

  terminateEvent(e: UpcomingEvents): void {
    this.eventService.removeFromUpcoming(e.id);

    this.notificatrionService.removeNotification(e.simulatorId, e.id);
    this.notificatrionService.removeExtendSessionsByEventId(e.id);

    this._eventWorkerStatus.next({
      status: EventsWorkerStatus.End,
      currentEvent: e,
    });
    
    this.helper.Notify(
      `${this.translate.instant('event')} ${e.name} ${this.translate.instant(
        'isOver'
      )}`,
      this.translate.instant('success'),
      3000
    );
    console.log('end event', e);
  }

  resetWorker(): void {
    if (this.eventsWorker) this.eventsWorker.terminate();
    this.initialWorker();
    this.eventsWorker.postMessage(
      JSON.stringify(
        new EventsWorkerConfigObj({
          status: EventsWorkerStatus.Start,
          events: this.eventService.upcomingEvents,
        })
      )
    );
  }
}
