export const environment = {
  production: true,
  wsEndpoint: "wss://v46l1f8ttd.execute-api.us-west-2.amazonaws.com/prod",
  awsAPIURL: 'https://l9otz28laj.execute-api.us-west-2.amazonaws.com/prod/bay-manager-explorer',
  apiServer: 'https://launcher.fullswingapps.com/api/fs',
  hostName: 'baymanager.fullswingapps.com',
  appConfigPath: './assets/config/app-config.json',
  defaultPage: 'event',
  //Local strorage values
  navName: '_fs_nav_name',
  bayManagerUser: 'Bay-Manager-User',
  calendarState: '_fs_fc_state',

  langId: '_fs_lang_id',
  defaultLanguage: 'en',
  defaultFontFamily: 'DavisSans',

  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },
}
