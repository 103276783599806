import { Component, OnInit } from '@angular/core';
import { BreadCrumbService } from './breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  constructor(
    public bcService: BreadCrumbService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  // checkLastRoute(route: string): boolean {
  //   let index = this.bcService.getBreadCrumbs.findIndex(
  //     (f) => f.route === route
  //   );
  //   return index === this.bcService.getBreadCrumbs.length - 1 ? false : true;
  // }
}
