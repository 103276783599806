export class CreateCustomerRequest {
  companyId?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;

  constructor(model: CreateCustomerRequest) {
    this.companyId = model.companyId || 0;
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.phoneNumber = model.phoneNumber || '';
    this.email = model.email || '';
  }
}
