import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[auto-complete]',
})
export class AutoCompleteDirective implements OnInit, AfterViewInit {
  formChangesSub: Subscription;

  @Input('formGroup') formGroup: FormGroup;
  @Input('excludes') excludes: string[];
  @Input('autoCompleteDisabled') autoCompleteDisabled: boolean;

  constructor(private element: ElementRef<HTMLFormElement>) {
    this.autoCompleteDisabled = false;
    this.formGroup = null;
    this.formChangesSub = null;
    this.excludes = new Array<string>();
  }

  ngAfterViewInit(): void {
    if (this.autoCompleteDisabled) return;

    if (this.formGroup) {
      this.formChangesSub = this.formGroup.valueChanges.subscribe(
        (fields: Object) => {
          if (fields && Object.keys(fields).length) {
            for (const key in fields) {
              if (!this.excludes.includes(key)) {
                let input = Array.from(this.element.nativeElement).find(
                  (input: HTMLInputElement) => input.name === key
                ) as HTMLInputElement;

                if (input) input.value = '';
              }
            }

            setTimeout(() => {
              this.formChangesSub.unsubscribe();
            }, 500);
          }
        }
      );
    }
  }

  ngOnInit(): void {}
}
