import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { QuickAccessResponse } from 'src/app/shared/models/Application.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavigationBarService {
  private _urlParam: Subject<string> = new Subject<string>();
  public quickAccess$ = new BehaviorSubject<QuickAccessResponse>(null as any);
  
  constructor() {}

  public setUrlParam(param: string): void {
    window.localStorage.setItem(environment.navName, param);
    this._urlParam.next(param);
  }

  public removeUrlParam(): void {
    window.localStorage.removeItem(environment.navName);
  }

  public getUrlParam(): Observable<string> {
    return this._urlParam;
  }
}
