import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { LicenseExpireModalComponent } from 'src/app/components/license-expire-modal/license-expire-modal.component';
import { AlertType } from '../../models/alert';
import { ExtendSession } from '../../models/extend-session';
import { HelperService } from '../../services/Helper.service';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  public alertType = AlertType;
  constructor(
    public dialog: MatDialog,
    public helperService: HelperService,
    public notificationService: NotificationService,
    public translate: TranslateService,
    public _bottomSheetRef: MatBottomSheetRef<ExtendSession>
  ) {}

  ngOnInit(): void {}

  openLicenseModal(): void {
    const dialogRef = this.dialog.open(LicenseExpireModalComponent, {
      width: '600px',
      backdropClass: 'background-blur',
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
}
