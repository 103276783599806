import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCardModule } from '@angular/material/card';

import { AuthGuard } from './shared/Auth.guard';
import { RestApiService } from './shared/services/RestApiServices.service';
import { ContentComponent } from './pages/content/content.component';
import { HttpInterceptorProviders } from './shared/InterceptorProvider';

import { WebSocketService } from './shared/services/WebSocket.service';
import { HelperService } from './shared/services/Helper.service';
import { LicenseExpireModalComponent } from './components/license-expire-modal/license-expire-modal.component';
import { LoaderModalComponent } from './components/loader-modal/loader-modal.component';
import { BreadcrumbModule } from './shared/modules/breadcrumb/breadcrumb.module';
import { NavigationBarService } from './components/nav-bar/nav-bar.service';

import { NotificationModule } from './shared/modules/notification/notification.module';
import { AuthService } from './shared/services/AuthService';
import { AuthComponent } from './pages/auth/auth.component';
import { MatInputModule } from '@angular/material/input';
import { EventModalComponent } from './components/event-modal/event-modal.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatSelectModule } from '@angular/material/select';
import { VersionModalComponent } from './components/version-modal/version-modal.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CookieService } from 'ngx-cookie-service';
import { CustomPipesModule } from './shared/pipes/custome-pipes.module';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpService } from './shared/services/http.service';
import { CustomersModalComponent } from './components/customers-modal/customers-modal.component';
import { RemoveModalComponent } from './components/remove-modal/remove-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomersService } from './pages/content/customers/customers.service';
import { LogsSheetModule } from './shared/modules/logs-sheet/logs-sheet.module';
import { IndexedDBService } from './shared/services/IndexedDB.service';
import { MiniLoaderService } from './shared/modules/mini-loader/mini-loader.service';
import { MiniLoaderModule } from './shared/modules/mini-loader/mini-loader.module';
import { EventService } from './shared/services/event.service';
import { SimulatorsService } from './pages/content/simulators/simulators.service';
import { EventWorkerService } from './shared/services/worker/event-worker/event-worker.service';
import { NotificationService } from './shared/modules/notification/notification.service';
import { IntegrationModalComponent } from './components/integration-modal/integration-modal.component';
import { IntegrationService } from './pages/content/integration/integration.service';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDividerModule } from '@angular/material/divider';
import { EventCommonService } from './pages/content/event/event-common.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { environment } from 'src/environments/environment';
import { LocaleService } from './shared/services/locale.service';
import { MatMenuModule } from '@angular/material/menu';
import { ImportEventsModalComponent } from './components/import-events-modal/import-events-modal.component';
import { AutoCompleteDirective } from './shared/directives/auto-complete.directive';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    NavBarComponent,
    ContentComponent,
    LicenseExpireModalComponent,
    LoaderModalComponent,
    EventModalComponent,
    VersionModalComponent,
    CustomersModalComponent,
    RemoveModalComponent,
    IntegrationModalComponent,
    AlertModalComponent,
    ImportEventsModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BreadcrumbModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTreeModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatCardModule,
    MatInputModule,
    NotificationModule,
    MatSelectModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatExpansionModule,
    NgxMatTimepickerModule.setLocale(getLanguageId()) /**.setLocale('en-US') */,
    CustomPipesModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    LogsSheetModule,
    MiniLoaderModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: getLanguageId(),
    }),
    AutoCompleteDirective,
  ],
  providers: [
    AuthGuard,
    HttpInterceptorProviders,
    RestApiService,
    WebSocketService,
    HttpService,
    HelperService,
    NavigationBarService,
    AuthService,
    CookieService,
    CustomersService,
    SimulatorsService,
    EventWorkerService,
    EventCommonService,
    IndexedDBService,
    MiniLoaderService,
    EventService,
    NotificationService,
    IntegrationService,
    LocaleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getLanguageId(): string {
  let langId = window.localStorage.getItem(environment.langId);

  if (langId?.length) return langId;
  else {
    window.localStorage.setItem(environment.langId, 'en');
    return 'en';
  }
}
