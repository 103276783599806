import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AppVersionModal } from 'src/app/shared/models/Application.model';
import { MODAL_DATA } from 'src/app/shared/staticObjects';

@Component({
  selector: 'app-version-modal',
  templateUrl: './version-modal.component.html',
  styleUrls: ['./version-modal.component.scss'],
})
export class VersionModalComponent implements OnInit, AfterViewInit {
  modalData: AppVersionModal[];

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(public dialogRef: MatDialogRef<VersionModalComponent>) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.modalData = MODAL_DATA;
    }, 100);
  }

  ngOnInit(): void {
    this.accordion?.closeAll();
  }
}
