import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { Subject, lastValueFrom } from 'rxjs';
import { SimulatorsService } from 'src/app/pages/content/simulators/simulators.service';
import { environment } from 'src/environments/environment';
import { ActiveConnection } from '../models/ActiveConnections';
import { Assistance } from '../models/assistance';
import { ClientType, SignalType } from '../models/Enums';
import { ExtendSession } from '../models/extend-session';
import { Signal } from '../models/Signal';
import { WebSocketSignalModel } from '../models/WebSocketSignal';
import { AuthService } from './AuthService';
import { RestApiService } from './RestApiServices.service';
import { Transaction } from '../models/transaction';
import { LocationWithSimulatorSelection } from '../models/location.model';
import { TranslateService } from '@ngx-translate/core';
export const WS_ENDPOINT = environment.wsEndpoint;

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  message: string;
  mainLoader: boolean;
  webSocket: WebSocket;
  intervalAuth: any;

  private sessionExtend = new Subject<ExtendSession>();
  public sessionExtend$ = this.sessionExtend.asObservable();

  private Assistance = new Subject<Assistance>();
  public Assistance$ = this.Assistance.asObservable();

  // Number == eventId
  private EndSession = new Subject<number>();
  public EndSession$ = this.EndSession.asObservable();

  private socketStatus = new Subject<string>();
  public socketStatus$ = this.socketStatus.asObservable();
  //socketStatus: string;
  public lock: SignalType = SignalType.LockDown;
  public unlock: SignalType = SignalType.Unlock;
  public connections: ActiveConnection[];
  reconnectAttempt = 1;
  private newSignal = new Subject<WebSocketSignalModel>();
  public newSignal$ = this.newSignal.asObservable();

  public newConnection = new Subject<ActiveConnection>();
  public newConnection$ = this.newConnection.asObservable();

  private Transaction = new Subject<Transaction>();
  public Transaction$ = this.Transaction.asObservable();

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private cookieService: CookieService,
    private restApiService: RestApiService,
    private SimService: SimulatorsService,
    private translate: TranslateService
  ) {
    this.message = '';
    this.socketStatus.next('Socket Connecting...');
    this.mainLoader = true;
    this.connections = new Array<ActiveConnection>();
    this.getOnlineSims();
  }

  private changeSocketState(state: number): void {
    switch (state) {
      case 0:
        this.socketStatus.next('Socket Connecting...');
        break;
      case 1:
        this.socketStatus.next('Socket Open');
        break;
      case 2:
        this.socketStatus.next('Socket Closing...');
        break;
      case 3:
        this.socketStatus.next('Socket Closed');
        break;
      default:
        break;
    }
  }
  public getSocketStatus() {
    return this.webSocket.readyState;
  }
  public sendSignal(
    simId: string,
    signalType: SignalType,
    startTime,
    endTime,
    message: string,
    extraParams: Record<any, any>
  ): any {
    this.authService.isTokenValid().then((res) => {
      //console.log('is token valid:  ' + res);
      if (!res) {
        this.authService.unautorized();
        return;
      }

      this.authService.isAuthenticated().then((res) => {
        //console.log('is authenticated:  ' + res);
        if (!res) {
          this.authService.unautorized();
          return;
        }

        this.mainLoader = true;

        this.authService.getCurrentUser().then(async (user) => {
          if (!user) return 'Error! User is not signed in';

          let tokenId = user.getSignInUserSession().getIdToken().getJwtToken();
          let userId = user.getUsername();

          if (tokenId?.length) {
            let connection = this.connections?.find((p) => p.simId == simId);

            if (!connection && signalType != SignalType.Transaction)
              return 'Error! Simulator is not connected';

            let connectionId =
              signalType == SignalType.Transaction
                ? 'Transaction'
                : connection.connectionId;

            // GET user Email
            //let email = await this.authService.getUserAttributebyKey('email');

            // GET company name
            //let companyName = '';
            // GET location name
            let locationName = '';
            let locations = localStorage.getItem('locationsandsims');
            let locationsList: LocationWithSimulatorSelection[];
            //let companyId;

            // await this.restApiService.userSub$.subscribe((user) => {
            //   if (user && user.company) {
            //     // setTimeout(() => {
            //     companyName = user.company.name;
            //     companyId = user.company.id;
            //     // }, 100);
            //   }
            // });

            if (!locations) {
              locationsList = await lastValueFrom(
                this.SimService.getLocationsAndSimulatorsSelection(
                  this.restApiService.user?.company?.id
                )
              );
              localStorage.setItem(
                'locationsandsims',
                JSON.stringify(locationsList)
              );
            } else {
              locationsList = JSON.parse(locations);
            }

            locationsList?.forEach((loc) => {
              loc.simulators?.forEach((sim) => {
                if (sim.simulatorIdentifier === simId) {
                  locationName = loc.name;
                  return;
                }
              });
            });
            
            // await this.restApi.getLocationNameBySimulatorId(86)
            // console.log(companyName);
            // console.log(locationName);
            let env = environment.hostName.split('.').join('');

            if (Object.keys(extraParams).length == 0) {
              extraParams = {
                'company-name': this.restApiService.user?.company?.name,
                'location-name': locationName,
                'sim-id': simId,
                'user-email': this.restApiService.user?.email,
                env: env,
              };
            } else {
              extraParams['company-name'] =
                this.restApiService.user?.company?.name;
              extraParams['location-name'] = locationName;
              extraParams['sim-id'] = simId;
              extraParams['user-email'] = this.restApiService.user?.email;
              extraParams['env'] = env;
            }

            if (signalType != SignalType.Transaction)
              this.notify(
                `${this.translate.instant('waitingToLauncher')}`,
                `${this.translate.instant('info')}`,
                20000
              );

            let signal = new Signal({
              signalType: signalType,
              userId: userId,
              connectionId: connectionId,
              status: signalType,
              type: ClientType.AdminPortal,
              startTime: startTime,
              endTime: endTime,
              message: message,
              simId: simId,
              extraParams: extraParams,
            });

            let json = JSON.stringify(
              new WebSocketSignalModel({
                action: 'onDirectMessage',
                payload: signal,
              })
            );
            this.webSocket.send(json);
            this.mainLoader = false;

            console.log('singal was sent: ', signal);
            return 'Signal was successfully sent';
          }
          return 'Error! User is not signed in';
        });
      });
    });
  }

  public sendSessionExtensionSignal(
    extendSignal,
    simId,
    reloadPage: boolean = false
  ): any {
    this.authService.isTokenValid().then((res) => {
      //console.log("is token valid:  " + res)
      if (!res) {
        this.authService.unautorized();
        return;
      }
    
      this.authService.isAuthenticated().then((res) => {
        //console.log("is authenticated:  " + res)
        if (!res) {
          this.authService.unautorized();
          return;
        }

        this.mainLoader = true;

        this.authService.getCurrentUser().then((user) => {
          if (!user) return 'Error! User is not signed in';

          let tokenId = user.getSignInUserSession().getIdToken().getJwtToken();
          let userId = user.getUsername();

          if (tokenId?.length) {
            let connection = this.connections?.find((p) => p.simId == simId);

            if (!connection) return 'Error! Simulator is not connected';
    
            let signal = new Signal({
              signalType: SignalType.Unknown,
              userId: userId,
              connectionId: connection.connectionId,
              type: ClientType.AdminPortal,
              simId: simId,
              endTime: 0,
              startTime: 0,
            });

            let json = JSON.stringify(
              new WebSocketSignalModel({
                action: 'onDirectMessage',
                payload: signal,
                extendSession: extendSignal,
              })
            );
            
            this.webSocket.send(json);
            this.mainLoader = false;
            
            if (reloadPage) document.location.reload();
            
            return 'Signal was successfully sent';
          }
          return 'Error! User is not signed in';
        });
      });
    });
  }

  public keepConnectionAlive() {
    setTimeout(() => {
      this.webSocket.send('ping');
    }, 10000);
  }

  public sendPingSignal() {
    setTimeout(() => {
      if (this.webSocket.readyState == this.webSocket.OPEN) {
        this.authService.getCurrentUser().then((user) => {
          if (!user) return 'Error! User is not signed in';

          let tokenId = user.getSignInUserSession().getIdToken().getJwtToken();
          let userId = user.getUsername();

          if (tokenId?.length) {
            this.connections?.forEach((connection, index) => {
              if (
                connection.isAlive != undefined &&
                connection.isAlive == false
              ) {
                var isMobile = /iPhone|iPad|iPod|Android/i.test(
                  navigator.userAgent
                );

                if (isMobile && !document.hasFocus()) {
                } else {
                  //this.SimService.setSimStatus(false,connection.simId)
                  console.log(
                    'sendPingSignal(): ' + connection.simId + ' is disconnected'
                  );
                  connection.signaType = SignalType.Disconnected;
                  this.newConnection.next(connection);
                  this.connections = this.connections.filter(
                    (f) => f.simId !== connection.simId
                  );
                  this.getOnlineSims();
                  // this.restApi.deleteSocketConnection(connection.simId).subscribe(p => {
                  //   location.reload()
                  // })
                }
              } else {
                let signal = new Signal({
                  signalType: SignalType.Ping,
                  userId: userId,
                  connectionId: connection.connectionId,
                  type: ClientType.AdminPortal,
                  simId: connection.simId,
                  endTime: 0,
                  startTime: 0,
                  extraParams: { ping: 'ping' },
                });
                let json = JSON.stringify(
                  new WebSocketSignalModel({
                    action: 'onDirectMessage',
                    payload: signal,
                    extendSession: null,
                  })
                );
                // setTimeout(() => {
                this.webSocket.send(json);
                //}, 5000 + 2000 * index);
                connection.isAlive = false;
                console.log(
                  'ping: ' +
                    connection.simId +
                    ' -connection number:' +
                    index +
                    ' at:',
                  new Date().toLocaleTimeString()
                );
              }
            });
            // return 'Signal was successfully sent';
          }
          return this.sendPingSignal();
        });
      }
    }, 20000);
  }
  public async openSocket() {
    if (this.webSocket)
      //console.log("state: ", this.webSocket.readyState)
      this.mainLoader = true;
    let self = this;
    this.authService
      .getCurrentUser()
      .then((user) => {
        if (!user) {
          self.webSocket.close();
        } else {
          let idToken = user.getSignInUserSession().getIdToken().getJwtToken();
          let userId = user.getUsername();
          let tabId = sessionStorage.getItem('BayManager-TabId');
          let url =
            WS_ENDPOINT +
            '?Authorization=' +
            idToken +
            '&UserId=' +
            userId +
            '&ClientType=AdminPortal' +
            '&SimId=' +
            tabId;

          this.webSocket = new WebSocket(url);

          this.webSocket.addEventListener('open', (e: any) => {
            this.reconnectAttempt = 1;
            this.mainLoader = false;
            console.log('socket opened: ', new Date());
            console.log(e);
            this.changeSocketState(e.target.readyState);
            setTimeout(() => {
              this.getOnlineSims();
              this.sendPingSignal();
            }, 5000);
          });
          this.webSocket.addEventListener('close', (e: any) => {
            this.changeSocketState(e.target.readyState);
            setTimeout(() => {
              console.log(
                'Trying to connect. attempt: ' + this.reconnectAttempt
              );
              this.reconnectAttempt = ++this.reconnectAttempt;
              if (this.reconnectAttempt <= 5) this.openSocket();
              else {
                this.changeSocketState(e.target.readyState);

                alert('You were signed out due to disconnection');
                location.href = '/';
              }
            }, 5000);
          });

          this.webSocket.addEventListener('error', (e: any) => {
            console.error('error: ', e);
            self.webSocket.close();
            this.changeSocketState(e.target.readyState);
          });

          this.webSocket.addEventListener('message', (e: any) => {
            let res = JSON.parse(e.data);

            if (res.Payload && res.Payload.ExtraParams) {
              let env = environment.hostName.split('.').join('');
              if (
                res.Payload.ExtraParams['env'] &&
                res.Payload.ExtraParams['env'] != env
              ) {
                return;
              }
            }
            // Server Error
            if (res.message && new String(res.message).indexOf('error') > -1) {
              let tabId = sessionStorage.getItem('BayManager-TabId');
            } else if (
              res.Payload.Type != ClientType.SimulatorTerminal &&
              (!res.Payload || !res.Payload.SignalType)
            )
              return;
            else if (
              res.Payload.Type != ClientType.SimulatorTerminal &&
              res.Payload &&
              res.Payload.SignalType == SignalType.Transaction
            ) {
              let transaction = JSON.parse(
                res.Payload.ExtraParams['transaction']
              );
              console.log('new transaction: ', transaction);
              this.Transaction.next(transaction);
            } else if (res.action == 'onDALDirectMessage') {
              if (res.Payload && res.Payload.SignalType == SignalType.Pong) {
                let con = this.connections.find(
                  (p) => p.simId == res.Payload.SimId
                );
                if (con) {
                  this.connections.find(
                    (p) => p.simId == res.Payload.SimId
                  ).isAlive = true;
                }
                console.log(
                  'pong from: ' +
                    res.Payload.SimId +
                    ' . at: ' +
                    new Date().toLocaleTimeString()
                );
              } else if (
                res.Payload &&
                res.Payload.SignalType == SignalType.SignalReceived
              ) {
                this.notify(
                  `${this.translate.instant('launcherReceivedSignal')}`,
                  `${this.translate.instant('success')}`,
                  3000
                );
              } else if (res.ExtendSession) {
                let extendSession = new ExtendSession({
                  minutes: res.ExtendSession.Minutes,
                  eventId: res.ExtendSession.Eventid,
                  simId: res.ExtendSession.Simid,
                  userId: res.ExtendSession.Userid,
                  eventName: res.ExtendSession.Eventname,
                });
                this.sessionExtend.next(extendSession);
              } else if (res.Assistance) {
                let assistance = new Assistance({
                  simId: res.Assistance.Simid,
                  type: res.Assistance.Type,
                  userId: res.Assistance.Userid,
                });

                // this.restApi.getAllClients().subscribe(p=>{
                //   console.log(p)
                // })
                this.Assistance.next(assistance);
              } else if (
                res.Payload &&
                res.Payload.SignalType == SignalType.endSession
              ) {
                this.cookieService.set(
                  'BM-event-canceled-' + res.Payload.ExtraParams['event-id'],
                  res.Payload.ExtraParams['event-id'],
                  { expires: 1, sameSite: 'Lax' }
                );
                console.log(res.Payload.ExtraParams['event-id']);

                this.EndSession.next(res.Payload.ExtraParams['event-id']);
              } else if (
                res.Payload &&
                res.Payload.SignalType == SignalType.Reconnected
              ) {
                let simId = res.Payload.ExtraParams['event-id'];
                console.log('launcher reconnected: ' + simId);
                // this.restApi.getAllClients().subscribe(p=>{
                //   console.log(p)
                // })
              }
            } else if (res.action == 'Update') {
              this.connections = this.connections.filter(
                (f) => f.simId !== res.Payload.SimId
              );
              let currentDisconnectedSim = this.connections.find(
                (p) => p.connectionId == res.Payload.ConnectionId
              );
              if (!res.Payload.SimId && currentDisconnectedSim) {
                res.Payload.SimId = currentDisconnectedSim.simId;
              }
              let newConnection = new ActiveConnection({
                signaType: res.Payload.SignalType,
                simId: res.Payload.SimId,
                status: res.Payload.Status,
                connectionId: res.Payload.ConnectionId,
                isAlive:
                  res.Payload.SignalType === SignalType.Connected
                    ? true
                    : false,
                notify: true,
              });
              this.newConnection.next(newConnection);
              if (res.Payload.SignalType === SignalType.Connected) {
                this.connections.push(newConnection);
              } else if (res.Payload.SignalType === SignalType.Disconnected) {
                console.log(
                  'Socket Alert: ' +
                    res.Payload.ConnectionId +
                    ' is disconnected'
                );
                this.connections = this.connections.filter(
                  (f) => f.connectionId !== res.Payload.ConnectionId
                );
                this.getOnlineSims();
              }
            }
            this.changeSocketState(e.target.readyState);
          });

          this.keepConnectionAlive();
        }
      })
      .catch((err) => console.error(err));
    this.mainLoader = false;
  }

  public closeSocket() {
    this.webSocket.close();
    this.authService.unautorized();
  }

  public async getOnlineSims(): Promise<void> {
    this.intervalAuth = setInterval(async () => {
      let isAuth = await this.authService.isAuthenticated();
      if (isAuth) {
        clearInterval(this.intervalAuth);
        this.restApiService.getAllClients().subscribe((clients) => {
          this.connections = clients.filter(
            (p) => p.type == ClientType.SimulatorTerminal
          );
          this.connections.forEach((connection) => {
            let newConnection = new ActiveConnection({
              signaType: SignalType.Connected,
              simId: connection.simId,
              status: SignalType.Connected,
              connectionId: connection.connectionId,
              isAlive: true,
              notify: false,
            });
            this.newConnection.next(newConnection);
          });
          console.log('online sims were loaded', this.connections);
        });
      }
    }, 500);
  }

  notify(msg: string, action: string, duration: number = 3000): void {
    this.snackBar.open(msg, action, {
      duration: duration,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      //announcementMessage :
    });
  }

  public dismiss(): void {
    this.snackBar.dismiss();
    event.preventDefault();
  }
}
