import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/shared/models/Application.model';
import { CustomerResponse } from 'src/app/shared/models/customer/customer-response.model';
import { CustomerSelectList } from 'src/app/shared/models/customer/customer-select-list.model';
import { CustomerResponseView } from 'src/app/shared/models/customer/customer-view.model';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable()
export class CustomersService extends HttpService<
  CustomerResponseView,
  CustomerResponse,
  ApiResponse,
  ApiResponse
> {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super(httpClient);

    this._httpClient = httpClient;
  }

  public getCustomers(companyId: number): Observable<CustomerResponseView[]> {
    const url = this.getUrl + `/Customer/bm-get-customers/${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .get<CustomerResponseView[]>(url, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getCustomersSelectList(
    companyId: number
  ): Observable<CustomerSelectList[]> {
    const url = this.getUrl + `/Customer/select-list/${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._httpClient
      .get<CustomerSelectList[]>(url, {
        headers: headers,
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }
}
